@import "./mixin.scss";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body{
    background-color: $light-blue;
}

h1{
    font-size: 70px;
    font-family: 'BrownStd';
    font-weight: normal;
    font-display: swap;
    font-style: normal;
}
h1{
    color: white;
    span{
        font-style: bold;
        font-weight: bold;
        
    }
    span.under{
        border-bottom: 10px solid $blue;
    }
}

h2{
    color: white;
    font-family: 'BrownStd';
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    span{
        text-decoration: underline;
    }
}
h3{
    color: white;
    font-family: 'BrownStd';
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
h4{
    color: white;
    font-family: 'BrownStd';
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

p{
    color: white;
    font-family: 'BrownStd';
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}