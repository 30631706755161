// SwipeHintOverlay.module.scss
.swipe-hint-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.5s ease-out;
    z-index: 1000;
    background-color: rgba(gray, 1);
  
    &.visible {
      opacity: 0.75;
    }
  }
  
  
  @keyframes swipe-animation {
    0%{
      opacity: 0;
      transform: translate(-50%, -50%) translateX(40vw);
    }
    1%{
      opacity: 0.75;
    }
    100% {
      transform: translate(-50%, -50%) translateX(10vw);

    }
  }
  