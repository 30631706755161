@import "../../scss/mixin.scss";

.footer {
    background-color: $light-blue;
    .footer-content {
        padding: 6rem 3rem;
        @include mobile{
            padding: 3rem 1.5rem;
        }
        .footer-row {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            @include mobile{
                flex-direction: column;
            }
            h2 {
                font-size: 60px;
                line-height: 75px;
                font-weight: normal;
                color: $blue;
                span {
                    font-weight: bold;
                    font-style: bold;
                }
                @include mobile{
                    font-size: 40px;
                    line-height: 50px;
                }
            }
            p.dis {
                width: 100%;
                font-size: 10px;
                color: $blue;
                @include mobile{
                    width: 100%;
                }
            }
            .company {
                width: 50%;
                img {
                    height: 67px;
                }
                img.cbre {
                    height: 42px;
                }
                @include mobile{
                    padding-top: 3rem
                }
                .agents {
                    padding: 3rem 0;
                    display: flex;
                    @include mobile{
                        flex-direction: column;
                        padding: 1.5rem 0;
                    }

                    .agent {
                        display: flex;
                        flex-direction: column;
                        width: 50%;

                        a{text-decoration: none;}
                        @include mobile{
                            padding-right: 0;
                            padding-bottom: 1.5rem;
                        }
                        p {
                            color: $blue;
                        }
                        p:first-child {
                            font-weight: bold;
                        }
                    }
                }
            }
        }
        .footer-row:last-child {
            @include mobile{                
                flex-direction: column-reverse;
            }
        }
    }
    .map{
        margin-top: -4.5rem;
        width: 40%;
        position: relative;
        @include mobile{
            width: 100%;
            margin-top: 3rem;
        }
        a{
            position: absolute;
            background-color: $blue;
            padding: 1rem 1.5rem;
            
            p{
                color: white;
                text-decoration: underline;
                font-size: 10px;
            }
        }
        img{
            width: 100%;
        }
    }
}

.stencil {
    
    padding-bottom: 1.5rem;
    svg {    
        width: 100%;
    }
    display: flex;
    justify-content: center;
    align-items: center;
}

.siren{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1rem 0;
    a{
        color: $blue;
        text-decoration: none;
        cursor: pointer;
        &:hover{
            text-decoration: underline;
        }
        p{
            color: $blue;
        }
    }
}
