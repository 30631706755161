@import "../../scss/mixin.scss";

.slide-hero {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.logo {
    position: absolute;
    top: 3rem;
    left: 1.5rem;
    z-index: 20;
    width: 200px;
    @include mobile{
        top: 1.5rem;
        width: 150px;
    }
}
.big-logo {
    position: absolute;
    display: flex;
    
    z-index: 20;
    width: 40vw;
    top: 15%;
    left: 5vw;
    @include mobile{
        width: 100%;
        display: flex;
        justify-content: center;
        left: 10%;
        top: 8.5%;
        width: 80%;        
    }
}

.touch {
    position: absolute;
    top: 3rem;
    right: 1.5rem;
    z-index: 20;
    @include mobile{
        top: 1.5rem;
    }
    a {
        cursor: pointer;
        text-decoration: none;
        h4 {
            font-size: 15px;
            color: white;
            border-bottom: 2px solid white;
            @include mobile{
                font-size: 11px;
            }
        }
    }
}

.one {
    background-color: $blue;
    display: flex;
    justify-content: space-between;
    .hero-image-one {
        background-image: url("../../../public/images/1.jpg");
        width: 50%;
        height: 100vh;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    
    .content {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100vh;
        padding: 3rem;
        align-items: center;
        .main-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            h3 {
                font-size: 40px;
            }
            p {
                font-size: 30px;
                padding-top: 3rem;
            }
        }
        .bot-content {
            h3 {
                font-size: 40px;
            }
        }
    }
    @include mobile{
        flex-direction: column;  

         .placeholder{
            display: none;
         }
        .content{
            height: 50vh;
            width: 100%;
            padding: 20% 1.5rem 1.5rem 1.5rem;
            justify-content: space-between;
            .main-content{
                h3{
                    font-size: 24px;
                }
                p{
                    font-size: 18px;
                    padding: 1.5rem 0;
                }
            }
            .bot-content{
                h3{
                    font-size: 24px;
                }
            }
        }
        .hero-image-one{
            width: 100%;
            height: 50vh;        
            background-attachment: fixed;
        }
    }
}

.two {
    background-color: $blue;
    @include mobile{
        display: none;
    }
    .image-wrapper-one {
        position: absolute;
        top: 0;
        right: 50%;
        .image-one {
            background-image: url("../../../public/images/2.jpg");
            height: calc(30vh + 1.5rem);
            width: 50vw;
            background-size: cover;
            background-position: 0;
            position: relative;

                p{
                    position: absolute;
                    font-size: 10px;
                    right: 0.5rem;
                    bottom: 0.5rem;
                }
        }
        .copy {
            p {
                padding-top: 1rem;
                padding-left: 3rem;
                
                font-size: 12px;
                font-family: "BrownStd";
            }
        }
    }
    .image-wrapper-two {
        position: absolute;
        top: 0;
        right: 10%;
        .image-two {
            background-image: url("../../../public/images/4.jpg");
            height: calc(30vh + 1.5rem);
            width: 30vw;
            background-size: cover;
            background-position: 0;
            position: relative;
            p{
                position: absolute;
                font-size: 10px;
                left: 0.5rem;
                bottom: 0.5rem;
            }
        }
        .copy {
            display: flex;
            flex-direction: row-reverse;
            p {
                padding-top: 1rem;
                padding-right: 0;
                font-size: 12px;
                font-family: "BrownStd";
            }
        }
    }
    .image-wrapper-three {
        position: absolute;
        bottom: 1.5rem;
        right: 50%;
        display: flex;
        align-items: flex-end;
        .image-three {
            background-image: url("../../../public/images/3.jpg");
            height: 30vh;
            width: 35vw;
            background-size: cover;
            background-position: 0;
            position: relative;
            p{
                position: absolute;
                font-size: 10px;
                right: 0.5rem;
                bottom: 0.5rem;
            }
        }
        .copy {
            display: flex;
            flex-direction: row-reverse;
            p {
                padding-top: 1.5rem;
                padding-right: 1.5rem;
                font-size: 12px;
                font-family: "BrownStd";
            }
        }
    }

    .table-wrapper {
        position: absolute;
        bottom: 1rem;
        right: 10%;
        .table {
            display: flex;
            flex-direction: column;
            color: white;
            .row {
                display: flex;
                min-width: 400px;
                .cell {
                    width: 25%;
                }
                .cell:first-child {
                    width: 50%;
                }
            }
            .row {
                padding-bottom: 0.25rem;
                padding-top: 0.25rem;
                border-bottom: 1px solid rgba(white, 0.5);
                text-transform: uppercase;
                font-family: "BrownStd";
                font-weight: normal;
                font-size: 13px;
            }
            .row:first-child {
                border-bottom: 1px solid transparent;
                text-transform: uppercase;
                font-family: "BrownStd";
                font-weight: bold;
            }
            .row.terrace {
                border-bottom: 1px dotted white;
                text-transform: uppercase;
                font-family: "BrownStd";
            }
            .row:nth-child(9){
                border-bottom: 1px solid white;
            }
            .row:last-child {
                border-bottom: 0;
                text-transform: capitalize;
                font-weight: bold;
            }
        }
    }
}

.two-mobile{
    display: none;
    background-color: $blue;
    @include mobile{
        display: flex;
    }
    height: 100vh;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    .title{
        padding: 4rem 0 0 0;
        h1{
            font-size: 30px;
            padding: 1.5rem;
        }
    }
    #three-swiper{
        width: calc(100% - 2rem);
        height: 40vh;
        .image-wrapper{
            width: 100%;
            height: 100%;
            .image{
                height: 40vh;

                width: 100%;

                background-size: cover;
                &.one-image{
                    background-image: url('../../../public/images/2.jpg');
                }
                &.two-image{
                    background-image: url('../../../public/images/3.jpg');
                }
                &.three-image{
                    background-image: url('../../../public/images/4.jpg');
                }
            }    
        }
    }
    .table-wrapper {
        padding: 1.5rem;
        width: calc(100% - 1rem);
        .table {
            display: flex;
            flex-direction: column;
            color: white;
            .row {
                display: flex;
                width: 100%;                
                .cell {
                    width: 25%;
                }
                .cell:first-child {
                    width: 50%;
                }
            }
            .row {
                padding-bottom: 0.15rem;
                padding-top: 0.15rem;
                border-bottom: 1px solid white;
                text-transform: uppercase;
                font-family: "BrownStd";
                font-weight: normal;
                font-size: 12px;
            }
            .row:first-child {
                border-bottom: 2px solid white;
                text-transform: uppercase;
                font-family: "BrownStd";
                font-weight: bold;
            }
            .row.terrace {
                border-bottom: 1px dotted white;
                text-transform: uppercase;
                font-family: "BrownStd";
            }
            .row:last-child {
                border-bottom: 0;
                text-transform: capitalize;
                font-weight: bold;
            }
        }
        .disc {
            color: white;
            font-size: 9px;
            font-family: "BrownStd";
            padding-left: 50%;
        }
    }
}

.three {
    .background-image {
        width: 100%;
        height: 100vh;
        background-size: cover;
        background-position: 25% 25%;
        background-repeat: no-repeat;
        background-image: url("../../../public/images/city.jpg");
        @include mobile{
            background-position: 25%;
        }
        .line-wrapper {
            position: absolute;
            top: 55%;
            right: 32.5%;
            display: flex;
            flex-direction: column;
            align-items: center;
            .top {
                width: 5px;
                height: 2px;
                background-color: white;
            }
            .line {
                transform: translate(-50%, -50%);
                content: " ";
                height: 500px;
                width: 2px;
                background-color: white;
            }
        }
    }
}

.four {
    min-height: 700px;
    @include mobile{
        display: none;
    }
    background-color: $blue;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h1 {
        color: white;
        span.under {
            border-color: white;
        }
    }

    .icons-top {
        display: flex;
        justify-content: space-between;
        padding: 1.5rem 1.5rem;
        @include large-desktop {
            padding: 4.5rem 1.5rem;
        }
        width: 100%;
        .icon-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 20%;            
            &.middle{
                padding: 0 1.5rem;
                justify-content: space-between;

            }
            .copy-hdr{
                padding: 3rem 0 0 0;
                p{
                    font-size: 15px;
                    text-align: center;
                }
            }
            .icon {
                width: 130px;
                height: 130px;
                background-color: $light-blue;
                border-radius: 50% 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                svg {
                    width: 80px;
                    &.smaller{
                        width: 65px;
                    }
                }
                img {
                    width: 100px;
                }
            }
            .copy {
                text-align: center;
                padding-bottom: 1.5rem;
                padding-top: 1.5rem;
                .blue{
                    color: $light-blue;
                    font-size: 14px;
                
                }
                p{
                    color: white;
                    font-size: 14px;
                }
            }
        }
    }
    .disc {
        position: absolute;
        bottom: 1rem;
        p {
            color: white;
            font-size: 11px;
        }
    }
}
.four-mobile{
    display: none;
    background-color: $blue;
    padding: 0rem 0 3rem 0;
    @include mobile{
        display: flex;
        flex-direction: column;
    }
    .title{
        padding: 4rem 0 0 0;
        h1{
            color: white;
            
            font-size: 30px;
            padding: 1.5rem;
            span{
                border-color: white
            }
        }
    }
    .icons-wrapper{
        display: flex;
        flex-direction: column;        
        height: 100%;
        justify-content: center;
        .icons{
            display: flex;
            justify-content: space-between;            
            .icon{
                padding: 0.5rem 0;                
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                .icon-image{
                    background-color: $light-blue;
                    border-radius: 50%;
                    width: 75%;
                    aspect-ratio: 1 / 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    
                    svg{
                        width: 60%;
                        height: 75%;
                        &.smaller{
                            width: 50%;
                            height: 60%;
                        

                        }
                    }
                }
                
                .icon-copy{
                    p{
                        text-align: center;
                        padding-top: 1.5rem;
                        color: white;
                        font-size: 12px;
                    }
                }
                &:last-child{
                    border-right: 0;
                }
            }
            &:last-child{
                border-bottom: 0;
            
            }
            &:last-child{
                .icon{
                    width: 50%;
                }
            }
        }
    }
}


#hero-swiper {
    .swiper-button-prev {
        content: url("../../../public/images/arrow.png");
        transform: scale(-1);
        width: 40px !important;
        left: 3rem;
        @include mobile{
            display: none;
        }
    }
    .swiper-button-next {
        content: url("../../../public/images/arrow.png");
        transform: scale(1);
        width: 40px !important;
        right: 3rem;
        @include mobile{
            display: none;
        }
    }
    .swiper-button-disabled {
        display: none;
    }
}

