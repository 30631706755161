$beige : #E8C8B2;
$light-blue: #97B9E2;
$blue: #283583;

@mixin large-desktop {
    @media (min-width: 1500px) {
        @content;
    }
}


@mixin desktop {
    @media (min-width: 1200px) {
        @content;
    }
}

@mixin small-desktop {
    @media (min-width: 1024px) and (max-width: 1200px) {
        @content;
    }
}

@mixin tablet {
    @media screen and (min-width: 768px) and (max-width: 1023px) {
        @content;
    }
}

@mixin mobile {
    @media screen and (max-width: 767px) {
        @content;
    }
}

@font-face {
    font-family: 'BrownStd';
    src: url('../../public/fonts/BrownStd-Regular.woff2') format('woff2'),
        url('../../public/fonts/BrownStd-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'BrownStd';
    src: url('../../public/fonts/BrownStd-Bold.woff2') format('woff2'),
        url('../../public/fonts/BrownStd-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

